import { Highlight, TextLink } from "../components/InlineTextFormatting";
import MyWebsite from "../components/ProjectPages/MyWebsite";

export const projectsData = {
    cycorgs: {
        title: "Cycorgs",
        sorting_order: 1,
        type: "Game",
        url: "/projects/cycorgs",
        subtitle: "3D City Builder Game",
        date: "July 2022",
        thumbnail: "data/thumbnails/cycorgs_thumbnail.webp",
        intro_text: <>
            <p>
                Cycorgs is a multidimensional <Highlight>strategic city-builder</Highlight>, in which you have to
                fulfill the needs of your Cycorgs (Cyberfied Corgies). Instead of building
                in just one world, Cycorgs features 7 different dimensions. Each with its
                own properties.
            </p>
            <p>
                Cycorgs was made by the team "7 dimensions games" and was our first 3d game.
                It scored first place at the Games.Kickstart event, hosted by
                the <TextLink url={"https://www.hnu.de/"} external>HNU</TextLink>.
            </p>
        </>,
        link_button: {
            url: "https://7-dimensions-games.itch.io/cycorgs",
            text: "see more on itch.io"
        },
        sections: [
            <>
                <h1>My work on the project</h1>
                <p>
                    I was the <Highlight>Lead Programmer</Highlight> and <Highlight>Lead Game Designer</Highlight> in our development team.
                    I also worked on a few 3D assets for the game.
                </p>
                <p>
                    As the <Highlight>Lead Programmer</Highlight>, I wrote most of the code for Cycorgs. From our initial
                    prototype to the current status of the game the most important task was to make
                    sure that every department could implement their work as seamlessly as possible.
                </p>
                <p>
                    As the <Highlight>Lead Game Designer</Highlight>, I helped to develop our unique core mechanic
                    (dimensions) and the game system. My responsibilities also lay in the
                    balancing and playtesting of Cycorgs. Together with the Game Design
                    Department, we had to ensure smooth interactions between the several
                    mechanics and 24 different buildings of the game.
                </p>
                <p>
                    I also helped at modeling <Highlight>3D Assets</Highlight>, such as the buildings: school, farm,
                    factory, and recycling station.
                </p>
            </>,
        ],
        image_query: {
            public_path: "/project_images/cycorgs/",
            prefix: "cycorgs_",
            suffix: ".webp",
            max_index: 6
        }
    },
    tvin_rooms: {
        title: "TVIN ROOMS",
        sorting_order: 1.5,
        type: "Game",
        url: "/projects/tvin_rooms",
        subtitle: "3D Puzzle Game - Game Jam Entry",
        date: "November 2024",
        thumbnail: "data/thumbnails/tvin_rooms_thumbnail.webp",
        intro_text: <>
            <p>
                TVIN ROOMS is a 3D <Highlight>spot-the-difference</Highlight> puzzle game, set in the swedish 
                furniture store TVIN at night.
            </p>
            <p>
                The game was created within 48 hours, by 9 developers at a <Highlight>Game Jam</Highlight>, 
                hosted by students from the
                <TextLink url={"https://www.hnu.de/"} external noUnderline>HNU</TextLink>. 
                I attended this Game Jam during my time at 
                the <TextLink url={"https://www.tha.de/"} external noUnderline>THA</TextLink>, 
                for my master studies.
            </p>
        </>,
        link_button: {
            url: "https://synthetix.itch.io/tvin-rooms",
            text: "see more on itch.io"
        },
        sections: [
            <>
                <h1>My work on the project</h1>
                <p>
                    My main role on the project was as part of the <Highlight>Game Design</Highlight> and <Highlight>
                    Management</Highlight> department. Together we refined the central idea, resolved game-design-conflicts
                    and were the vision keepers to guide towards the shared game vision. We also overlooked the work
                    of all of our other team members in order to optimize processes in the very tight time frame.
                </p>
                
                <p>
                    I also helped at modeling a few <Highlight>3D Assets</Highlight> and realized the idea of our
                    ingame companies' Corporate Identity and <Highlight>Graphic Design</Highlight> - creating logos,
                    color palettes, world elements and swedish narrative elements for the brand TVIN.
                </p>
            </>,
            <>
                <h1>Result of the Game Jam</h1>
                <p>
                    Out of 8 entries to the on-site game jam to the theme "no turning back", we reached the <Highlight>3rd place in "Best 
                        Game"</Highlight> (main category) and the <Highlight>1st place in "Best Visuals"</Highlight>.
                    
                </p>
                <p>
                    See more on the Game Jams
                    <TextLink url={"https://itch.io/jam/hnu-winter-jam-2024"}
                    external 
                    >itch.io page</TextLink>.

                </p>
            </>
        ],
        image_query: {
            public_path: "/project_images/tvin_rooms/",
            prefix: "tvin_rooms_",
            suffix: ".webp",
            max_index: 5
        }
    },
    website: {
        title: "This Website",
        sorting_order: 4,
        type: "Web",
        url: "/projects/website",
        subtitle: "Portfolio Website - Webdevelopment Project",
        date: "May 2024",
        thumbnail: "project_images/website/website_thumbnail.webp",
        intro_text: <>
            <p>
                I created this portfolio website using React.js. On here
                I can showcase most of my projects and give further information
                about my work on them.
            </p>
            <p>
                You can also read more <TextLink url={"/about_me"}>about me</TextLink>, look at
                my resume or contact me directly.
            </p>
        </>,
        link_button: null,
        sections: [
            <>
                <MyWebsite />
            </>
        ],
        images: [

        ]
    },
    controls_crossover: {
        title: "Controls Crossover",
        sorting_order: 2,
        type: "Game",
        url: "/projects/controls_crossover",
        subtitle: "Game with reversed controls (Game Jam)",
        date: "July 2023",
        thumbnail: "data/thumbnails/controls_crossover_thumbnail.webp",
        intro_text: <>
            <p>
                Controls Crossover is a small <Highlight>3D arcade game</Highlight>. Following the theme "Roles Reversed"
                of the 2023 GMTK Game Jam, the player control an ingame mouse with their keyboard
                and an ingame keyboard with their mouse. The player then has to bring their set of
                keyboard and mouse into a given position as fast as possible.
            </p>
            <p>
                The game was made by a small team of two developers (including me), for a game jam,
                within just two days.
            </p>
        </>,
        link_button: {
            url: "https://douji.itch.io/controls-crossover",
            text: "download on itch.io"
        }, sections: [
            <>
                <h1>My work on the project</h1>
                <p>
                    As one of only two developers in the team, I mainly worked on programming and game design.
                </p><p>
                    I did a lot of the <Highlight>Programming</Highlight>, using code design patterns, such as an event bus,
                    singletons and additive scene manegement in Unity. The main aspect of the game -
                    the controls - were not a huge difficulty, due to using common approaches like
                    raycasts and Unity's input system.
                </p><p>
                    For the <Highlight>Polishing</Highlight>, I implemeted some simple particles and post processing to
                    quickly improve the game's appearance and create some feedback for the player.
                </p><p>
                    I also helped with modeling <Highlight>3D Assets</Highlight>, creating the keyboard and mouse model.
                    For the keyboard I used blenders geometry nodes, which helped with placing
                    all the keys on the keyboard.
                </p><p>
                    The main <Highlight>challenge</Highlight> was the short time limit of the game jam of just two days.
                    Thankfully to our prior experience in developing games, we didn't have any
                    problems which would have taken a lot of time to fix.
                </p>
            </>,
            <>
                <h1>Result of the Game Jam</h1>
                <p>
                    Out of 6,818 entries to the GMTK Game Jam 2023 with the theme "Roles Reversed",
                    we reached an overall rank of <Highlight>#319</Highlight> and got very positive comments.
                </p>
                <p><TextLink url={"https://itch.io/jam/gmtk-2023/rate/2161367"}>
                    View results and comments here.
                </TextLink></p>

            </>
        ],
        image_query: {
            public_path: "/project_images/controls_crossover/",
            prefix: "controls_crossover_",
            suffix: ".webp",
            max_index: 2
        }
    },
    unisex: {
        title: "Unisex",
        sorting_order: 2,
        type: "Game",
        url: "/projects/unisex",
        subtitle: "Educational Game",
        date: "January 2022",
        thumbnail: "/project_images/unisex/unisex_thumbnail.webp",
        intro_text: <><p>
            Unisex is an <Highlight>educational 2D game</Highlight>, in which the extraterrestrial "Aster"
            learns about safer sex methods and different sexual orientations on earth.
            Apart from that, the game also teaches the player about tolerance in its
            very empathic story.
        </p><p>
                Unisex was made by the team "Kontrolliertes Chaos" and was our first game
                designed for a touch device.
            </p></>,
        link_button: {
            url: "https://synthetix.itch.io/unisex",
            text: "play on itch.io"
        }, sections: [
            <>
                <h1>My work on the project</h1>
                <p>
                    I was the Lead Programmer in our development team and also helped with the game concept.
                </p><p>
                    As the <Highlight>Lead Programmer</Highlight>, I worked together with another programmer to overcome
                    challenges regarding our platform and technology of the game. Since this was
                    our first game primarily designed for a mobile touch device, we had no experience
                    in this matter.
                </p><p>
                    It was also the first time that we created a dialouge system that works with
                    connected entries and allows branching player choices.
                </p>
            </>
        ],
        image_query: {
            public_path: "/project_images/unisex/",
            prefix: "unisex_",
            suffix: ".webp",
            max_index: 8
        }
    },
    fruit_platformer: {
        title: "Fruit Platformer",
        sorting_order: 5,
        type: "Game",
        url: "/projects/fruit_platformer",
        subtitle: "Exercise in Game Design and Programming",
        date: "March 2022",
        thumbnail: "/project_images/fruit_platformer/fruit_platformer_thumbnail.webp",
        intro_text: <>
            <p>
                Fruit Platformer is a simple <Highlight>platformer</Highlight> game, in which the player
                has to reach the top, by jumping from platform to platform. Following
                the principal "easy to learn, hard to master", the game is intentionally
                designed to be quite hard, while having simple controls and intuitive
                mechanics.
            </p><p>
                Fruit Platformer was made by me,
                using <TextLink url={"https://pixelfrog-assets.itch.io/pixel-adventure-1"}>free art assets</TextLink> from
                the internet. This simple game was primarily a exercise project to practice
                programming and game design.
            </p>
        </>,
        link_button: {
            url: "https://simmer.io/@Synthetix/fruitplatformer",
            text: "play on simmer.io"
        }, sections: [
            <>
                <h1>My work on the project</h1>
                <p>
                    I was the solo developer on this project, which I started to practice
                    programming and game design.
                </p><p>
                    The <Highlight>Game Design</Highlight> was inspired from games like cuphead that have a
                    challenging difficulty, while still having a simple concept. Following
                    this "easy to learn, hard to master" approach, the game features several
                    dangers, that makes the player start from the bottom again, aswell as a
                    decaying score, that adds a speedrun character to the platformer. It
                    also has a few genre-typical mechanics like a double jump, wall jump
                    and dash. All mechanics and controls are taught to the player by NPCs
                    in the level.
                </p><p>
                    Using Unity, the <Highlight>programming</Highlight> was quite simple. I could use Unity's
                    physics system most of the platformers features. The probably biggest
                    challenge was making the controls and mechanics feel good. This meant
                    a lot of tweaking and trying out different approaches.
                </p><p>
                    To focus more on the goal of the project, I did not want to waste
                    time on creating <Highlight>art assets</Highlight> first, so I used free pixel art assets
                    from itch.io (see <TextLink url={"https://pixelfrog-assets.itch.io/pixel-adventure-1"}>
                        Pixel Adventure</TextLink>).
                </p >
            </>
        ],
        image_query: {
            public_path: "/project_images/fruit_platformer/",
            prefix: "fruit_platformer_",
            suffix: ".webp",
            max_index: 5
        }
    },
    cat_universe: {
        title: "Cat Universe",
        sorting_order: 5,
        type: "Game",
        url: "/projects/cat_universe",
        subtitle: "Simple shoot'em up Game",
        date: "February 2021",
        thumbnail: "/project_images/cat_universe/cat_universe_thumbnail.webp",
        intro_text: <>
            <p>
                Cat Universe is a <Highlight>shoot'em up</Highlight>, in which the player as a mouse has to dodge the attack of cats and has to shoot at them to defeat them. The game is designed to have a hard difficulty. It features several levels, with multiple different types of enemies with unique boss fights at the end of every stage.
            </p><p>
                This game was made by a group of 5 developers, and was one of the first games I ever worked on. It also was the first game we (and myself) ever made using the Unity Engine.
            </p>
        </>,
        link_button: {
            url: "https://douji.itch.io/cat-universe",
            text: "download on itch.io"
        }, sections: [
            <>
                <h1>My work on the project</h1>
                <p>
                    I was the <Highlight>Lead Programmer</Highlight> and also helped with Game- and Level Design.
                </p><p>
                    The <Highlight>Programming</Highlight> was quite easy, due to very simple mechanics. This was not only the first real game I ever made in Unity, but also the first time I ever worked with other developers together on a game.
                </p><p>
                    As part of the <Highlight>Game Design</Highlight> team, we developed the game concept together. My work on the Game Design specialized on level design, defining how the waves of enemies should spawn and what kind of boss fights there are. This game was also the first time we needed playtesting and balancing, because we wanted to create a very challenging game, which is almost impossible to beat.
                </p>
            </>
        ],
        image_query: {
            public_path: "/project_images/cat_universe/",
            prefix: "cat_universe_",
            suffix: ".webp",
            max_index: 5
        }
    },
    cybercourier: {
        title: "Cybercourier",
        sorting_order: 6,
        type: "Game",
        url: "/projects/cybercourier",
        subtitle: "Point and Click Game",
        date: "July 2021",
        thumbnail: "data/thumbnails/cybercourier_thumbnail.webp",
        intro_text: <>
            <p>
                Cybercourier is a <Highlight>simple point and click adventure</Highlight> demo, in which Cyberio has to get up and get ready for work as a cybercourier in a cyberpunk world. To get ready for his job - delivering cyberpackages - the very chaotic protagonist first has to find several objects in his apartment and solve puzzles, in order to finally get all his equipment.
            </p><p>
                This game was one of the first games I worked on. It's a very simple demo and only features the first scene of a potentially full game.
            </p>
        </>,
        link_button: {
            url: "https://synthetix.itch.io/cybercourier",
            text: "download on itch.io"
        }, sections: [
            <>
                <h1>My work on the project</h1>
                <p>
                    As one of the four members of our development team I primarily worked on the project as a programmer and game designer.
                </p><p>
                    As the <Highlight>Lead Programmer</Highlight>, I was responsible for implementing all features that we were given in the task as a semester's project. This includes a simple narrative monologue (with voice-over), an inventory system with item combination, a puzzle minigame to open a door and of course the point-and-click player controls. Using Unity's URP we also implemented some lighting into the scene, making the graphics really stand out and pick up the cyberpunk setting.
                </p><p>
                    Regarding the <Highlight>Game Design</Highlight>, I helped to develop the game concept as well as the puzzle design. A part from combining items, the player also has to solve an examplary puzzle to open a door. The demo, featuring the first scene, is supposed to be a horizontal slice of a partially concepted full game.
                </p>
            </>
        ],
        image_query: {
            public_path: "/project_images/cybercourier/",
            prefix: "cybercourier_",
            suffix: ".webp",
            max_index: 2
        }
    },
    // sketchfab: {
    //     title: "My Sketchfab Account",
    //     sorting_order: 9,
    //     type: "Link",
    //     url: "https://sketchfab.com/Synthetix",
    //     subtitle: "",
    //     thumbnail: "data/thumbnails/sketchfab_thumbnail.webp",
    //     intro_text: <></>,
    //     link_button: {
    //         url: "",
    //         text: "see more"
    //     }, sections: [
    //         <></>
    //     ],
    //     images: [

    //     ]
    // },
    strahlenjaeger: {
        title: "Strahlenjäger",
        sorting_order: 1,
        type: "Game",
        url: "/projects/strahlenjaeger",
        subtitle: "Educational Adventure Game",
        date: "January 2024",
        thumbnail: "project_images/strahlenjaeger/strahlenjaeger_thumbnail.webp",
        intro_text:
            <>
                <p>
                    Strahlenjäger is a small <Highlight autoSpace>educational adventure game</Highlight>
                    about protection from radioactivity.
                    As Alex Geigenstein, the player is tasked to help the people of a small town,
                    which are endangered by radiation.
                </p>
                <p>
                    The game uses a mixed artstyle between 2D paper-like characters and a 3D world.
                    Its unique selling point is a <Highlight>vibrantly beautiful world</Highlight> with a lot of details.
                </p>
                <p>
                    The game was created as part of a
                    semester at the <TextLink url={"https://www.hnu.de/"} external>HNU</TextLink>.
                </p>
            </>,
        link_button: {
            url: "https://bitbusters-production.itch.io/strahlenjaeger",
            text: "see more on itch.io"
        }, sections: [
            <>
                {/* <h1>My work on the project</h1> */}
                <h1>My work on the project</h1>
                <p>
                    Being the <Highlight>Lead Programmer</Highlight>, my task included to create a
                    clean and modular code structure. At its core is the Event Bus, a code design pattern
                    that uses centralized events, which make the code completly modular and easy to expand.
                    Using the best practices and advantages of the Unity game engine, this created clean
                    code which was easy to work with even at the end of the development phase.
                </p>
                <p>
                    Apart from programming a large part of the code for the game. I also helped with creating
                    <Highlight>3D Assets</Highlight> and defined the guidelines for implementation.
                </p>
                <p>
                    Combining coding and 3D art, my second largest area of work for the game was polishing
                    the game with the use of <Highlight>Visual Effects</Highlight>.
                    These simple effects increased the quality and feel of the game and gave us better
                    possibilities to create a visually appealing game and communicate with the player.
                    <br />To list some examples, I created: fireflies around light sources, effects when collecting items
                    or completing quests, and stationary effects to guide the player.
                </p>
                <p>
                    During the project I learned about an advanced way to
                    create <Highlight>User Interface</Highlight> in Unity, which
                    heavily increased its quality, since it made it easy to create fluid animations.
                </p>
                <p>
                    In general, the game teached me the enormous value of detail-oriented polishing
                    and tiny, but significant pieces of eye candy.
                </p>
                <p>
                    Acting in my role of the Technical Lead, I consulted our
                    <Highlight autoSpace>Game Design</Highlight> department about the technical possibilities
                    in our very tight time schedule. As a result, this heavily shaped the mechanics of the game
                    while still keeping the vision of the Game Designers.
                </p>
                <p>
                    So far this was my biggest project, since it's a story game with several different
                    mechanics along its path and a small, but detailed world.
                </p>
            </>
        ],
        image_query: {
            public_path: "/project_images/strahlenjaeger/",
            prefix: "strahlenjaeger_",
            suffix: ".webp",
            max_index: 15
        }
    },
    hnuesports: {
        title: "HNUeSports",
        sorting_order: 4.5,
        type: "Graphics",
        url: "/projects/hnuesports",
        subtitle: "Graphic Design",
        date: "2022",
        thumbnail: "/project_images/hnuesports/hnuesports_thumbnail.webp",
        intro_text:
            <>
                <p>
                    HNUeSports is an eSports organisation we founded during our first semester
                    at the <TextLink url={"https://www.hnu.de/"} external>HNU</TextLink>.
                    We established a hub for eSports at our university, launching multiple successful eSport teams
                    for several games.
                </p>
                <p>
                    As the Lead Graphic Designer for 2 years and one of the founding members, I created
                    countless graphics, concepts and identities for the teams and the organisation.
                </p>
            </>,
        link_button: {
            url: "",
            text: "see more"
        }, sections: [
            <>
                <p className="cursive">
                    Disclaimer: The logo of HNUeSports implements the 
                    <TextLink url={"https://www.hnu.de/"} external autoSpace>universities</TextLink> 
                    logo.
                </p>
                <h1>My work in the organisation</h1>
                <p>
                    During my time, I worked together with the rest of the organisation
                    to come up with identities for the organisation itself and the teams.
                    After defining these concepts, I designed Infographics, Social Media posts,
                    Streaming Overlays, Animations, Posters and everything else that we needed.

                </p>
                {/* <p>
                    I was granted the freedom to explore several Design concepts and new ways
                    to create Assets.
                </p> */}
            </>
        ],
        image_query: {
            public_path: "/project_images/hnuesports/",
            prefix: "hnuesports_",
            suffix: ".webp",
            max_index: 14
        }
    },
    // todo: narrative design, immersight internship, texture collection?, 3d art collection, 
    // todo: graphic design (hnuesports, lol cup neu ulm, hnu darkin, hnu origin, hnuesports old)

    template: {
        title: "Template",
        sorting_order: 1,
        type: "",
        url: "/projects/cycorgs",
        subtitle: "",
        thumbnail: "data/thumbnails/placeholder.webp",
        intro_text: <></>,
        link_button: {
            url: "",
            text: "see more"
        }, sections: [
            <></>
        ],
        image_query: {
            public_path: "/project_images/strahlenjaeger/",
            prefix: "strahlenjaeger_",
            suffix: ".webp",
            max_index: 15
        }
    },
}