import { useRef, useState } from "react";
import imagePaths from "./imagePaths";
import heartIcon from "./icons/heart_icon.svg"
import closeIcon from "./icons/close_icon.svg"
import { useDragControls } from "framer-motion";
import { motion } from "framer-motion";

export default function Eyetracking() {
    const [imgIndex, setImgIndex] = useState(0);

    const dataSet = imagePaths.testing;

    const cardsData = Array.from(Array(10)).map((e, i) => {
        return dataSet.folder + "/" + dataSet.prefix + (i.toString().padStart(3, "0")) + dataSet.suffix;
    });

    const onDecision = (like) => {
        console.log(like ? "like" : "dislike")
        setImgIndex(imgIndex + 1);
    };

    return (
        <>
            <div style={styles.curtain}>
                {imgIndex <= dataSet.maxImageIndex ?
                    <>
                        <SwipeButton like={false} color={"#d41c1c"} onClick={() => onDecision(false)} />
                        <ImageCard src={cardsData[imgIndex]} onDecision={onDecision} />
                        <SwipeButton like={true} color={"#0c913d"} onClick={() => onDecision(true)} />
                    </> :
                    <>
                        done
                    </>
                }
            </div>
        </>
    );
}

function ImageCard({ src, onDecision }) {
    const controls = useDragControls();
    const cardRef = useRef(null);
    const dragLimit = 300;
    const likeThreshold = 200;
    const [startPos, setStartPos] = useState(-1);
    const [liking, setLiking] = useState(null);

    const OnDragEnd = (e) => {
        const diff = e.screenX - startPos;
        // console.log(e)
        if (diff < -likeThreshold) {
            onDecision(false)
        } else if (diff > likeThreshold) {
            onDecision(true)
        }
        setLiking(null);

    };

    const OnDrag = (e) => {
        const diff = e.screenX - startPos;
        // console.log(diff)
        if (diff < -likeThreshold) {
            setLiking(false);
        } else if (diff > likeThreshold) {
            setLiking(true);
        } else {
            setLiking(null);
        }
    }

    const OnDragStart = (e) => {
        setStartPos(e.screenX)
    };

    return (
        <motion.div
            style={styles.imageCard}
            ref={cardRef}
            drag="x"
            dragControls={controls}
            dragConstraints={{ left: -dragLimit, right: dragLimit }}
            dragSnapToOrigin={true}
            onDragEnd={(e) => { OnDragEnd(e) }}
            onDragStart={(e) => { OnDragStart(e) }}
            onDrag={(e) => { OnDrag(e) }}
        // onDragEnter={}
        >
            <img src={src} style={styles.image} draggable={false} />
            <div
                style={
                    {
                        ...styles.imageLikeFeedback,
                        ...(liking === null ? {} : liking ? styles.imageLiking : styles.imageDisliking)
                    }}
            />

        </motion.div>
    )
}

function SwipeButton({ like, color, onClick }) {
    const [hover, setHover] = useState(false);

    return (
        <div
            style={{ ...styles.button, ...(hover ? styles.buttonHover : {}), ...{ backgroundColor: color } }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => { onClick() }}
        >
            <img src={like ? heartIcon : closeIcon} style={styles.buttonIcons} draggable={false} />
        </div >
    )
}

const cardSize = "90dvh"

const styles = {
    curtain: {
        height: "100dvh", width: "100dvw",
        backgroundColor: "#000000",
        zIndex: "100000",
        display: "flex", flexDirection: "row",
        alignItems: "center", justifyContent: "center",
        gap: "4dvw",
        userSelect: "none"
    },
    imageCard: {
        position: "relative",
        height: cardSize, width: cardSize,
        // border: "1px solid white",
        overflow: "clip",
        backgroundColor: "#444444",
        display: "flex", alignItems: "center", justifyContent: "center",
        // objectFit: "contain",
        borderRadius: "1dvh"
        // maxHeight: "90vh"
    },
    image: {
        //  height: "100%",
        position: "absolute",
        height: "100%",
        maxHeight: cardSize,
        objectFit: "contain",
        // width: "100%",
        // zIndex: "100001"
    },
    imageLikeFeedback: {
        position: "absolute",
        width: "100%", height: "100%",
        // zIndex: "100002"
    },
    imageLiking: {
        backgroundColor: "rgba(0,255,0,0.25)",

    },
    imageDisliking: {

        backgroundColor: "rgba(255,0,0,0.25)",
    },
    button: {
        height: "16dvh", width: "16dvh",
        // border: "1px solid #ffffff",
        borderRadius: "1dvh",
        padding: "1dvh",
        transition: "transform 0.2s ease-in-out",
        zIndex: "100010"
    },
    buttonHover: {
        transform: "scale(1.1)"
    },
    buttonIcons: {
        height: "100%", width: "100%"
    }
}

